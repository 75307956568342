import api from "../axios_service.js";

export async function all(filtros) {
  const response = await api.get(`/admin/sales${filtros ? filtros : ""}`);
  return response.data.result;
}
export async function allDuplicadas(filtros) {
  const response = await api.get(
    `/admin/sales-duplicadas${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getLotes(filtros) {
  const response = await api.get(
    `/admin/eduzz/contents${filtros ? filtros : ""}`
  );
 return response; 
}

export async function getVendas() {
  const response = await api.get(`/admin/eduzz/sales`);
  return response.data.result;
}

export async function getProdutos() {
  const response = await api.get(`/admin/eduzz/products`);
  return response.data.result;
}

export async function getConteudos() {
  const response = await api.get(`/admin/eduzz/contents`);
  return response.data.result;
}

export async function syncConteudos() {
  const response = await api.get(`/admin/eduzz/sync-contents`);
  return response.data.result;
}

export async function syncVendas(id) {
  const response = await api.post(`/admin/eduzz/sync-sales/${id}`);
  return response.data;
}

export async function createConteudo(data) {
  const response = await api.post(`/admin/eduzz/contents`, data);
  return response.data;
}

